import queryString from 'query-string';

export function isContentOnly() {
    if (typeof window !== 'undefined') {
        return (
            queryString.parse(window.location.search)['content-only'] === 'true'
        );
    }
    return false;
}
