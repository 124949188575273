import React, { Component } from 'react';
import { Link } from 'gatsby';

import { DeadCenter } from '../layouts/deadcenter';
import Layout from '../layouts/index';
import * as routes from '../constants/routes';
import './betasignedup.scss';

class BetaSignedUp extends Component {
    render() {
        return (
            <Layout location={this.props.location}>
                <DeadCenter>
                    <div className="signedup-container">
                        <h1>Thanks! We'll be in touch.</h1>
                        <h5>
                            Thank you for your interest in Wrangle. We will let
                            you know as soon as we're ready to launch.
                        </h5>

                        <Link className="button secondary" to={routes.LANDING}>
                            Back to Home
                        </Link>
                    </div>
                </DeadCenter>
            </Layout>
        );
    }
}

export default BetaSignedUp;
