import React from 'react';
import { Link } from 'gatsby';
import * as routes from '../../constants/routes';

import './index.scss';

const Footer = (props) => {
    return (
        // <i className="fab fa-twitter-square"></i>
        <div className="footer-container">
            <div className="footer">
                {/* <i className="fab fa-twitter-square"></i> */}
                <div className="columns">
                    <div className="column is-two-thirds">
                        <ul>
                            <li className="footer-logo">Wrangle</li>
                            <li className="footer-tagline">Support, Augmented</li>
                            {/* <li className="small-footer"><i className="fab fa-twitter"></i> <a href="https://twitter.com/trywrangle">@usewrangle</a></li> */}
                            <li className="small-footer">&copy; Three Spatial LLC 2019</li>
                        </ul>
                    </div>
                    <div className="column">
                        <ul className="site-map">
                            {/* <li className="site-map-header">Product</li>
                            <li className="site-map-item">Home</li>
                            <li className="site-map-item">Pricing</li> */}
                        </ul>
                    </div>
                    <div className="column">
                        <ul className="site-map">
                            <li className="site-map-header">Legal</li>
                            <li className="site-map-item"><Link to={routes.PRIVACY}>Privacy Policy</Link></li>
                        </ul>
                    </div>
                </div>
            </div>


            {/* <ul className="is-pulled-right">
                <li>
                    
                </li>
            </ul>
            <div style={{ clear: 'both' }} /> */}
        </div>
    );
};

export default Footer;
