import React from 'react';
import { Link } from 'gatsby';
import * as routes from '../../constants/routes';

import './index.scss';

const Header = () => {
    return (
        // Original
        // <div className="container header">
        <div className="header">
            <div className="section">
                <Link to={routes.LANDING}>
                    <h1 className="logo">Wrangle</h1>
                </Link>
            </div>
        </div>
    );
};

export default Header;
