import React, { Component } from 'react';
import './deadcenter.scss';
export class DeadCenter extends Component {
    render() {
        return (
            <div className="deadcenter">
                <div className="deadcenter-inner">{this.props.children}</div>
            </div>
        );
    }
}
