import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Header from '../components/Header';
import Content from '../components/Content';
import Footer from '../components/Footer';
import { isContentOnly } from '../utils/location';
import 'whatwg-fetch';

import { initRollbar, initGA, initHotJar /*, initFullStory*/ } from './3rdparty';
import './index.scss';

// Initialize Analytics
initRollbar();
initGA();
initHotJar();
//initFullStory();

class TemplateWrapper extends Component {
    render() {
        let { children, location } = this.props;
        let bodyClassName = ['app'];

        if (isContentOnly()) {
            bodyClassName.push('content-only');
        }
        return (
            <div>
                <Helmet>
                    <title>Wrangle</title>
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1"
                        maximum-scale="1.0"
                        user-scalable="no"
                    />
                    <meta
                        name="description"
                        content="Wrangle offers 1-click video calls and simple tools to collaborate with customers over video and interact in their environment from the comfort of your desk."
                    />
                    <meta
                        name="keywords"
                        content="Wrangle, Support, Application, Draw, Augmented Reality, AR, Enterprise, Video"
                    />
                    <link
                        href="https://fonts.googleapis.com/css?family=Merriweather+Sans:400,400i,700,800"
                        rel="stylesheet"
                    />
                    <link
                        href="https://fonts.googleapis.com/css?family=Merriweather:300,400"
                        rel="stylesheet"
                    />
                    <link
                        rel="stylesheet"
                        href="https://pro.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-Bx4pytHkyTDy3aJKjGkGoHPt3tvv6zlwwjc3iqN7ktaiEMLDPqLSZYts2OjKcBx1"
                        crossorigin="anonymous">
                    </link>
                    <link
                        href="https://cdnjs.cloudflare.com/ajax/libs/bulma/0.7.1/css/bulma.min.css"
                        rel="stylesheet"
                    />
                    <script
                        async
                        src="https://www.googletagmanager.com/gtag/js?id=UA-125734430-1"
                    />
                </Helmet>
                <div className={bodyClassName.join(' ')}>
                    <Header location={location} />
                    <Content location={location}>{children}</Content>
                    <Footer location={location} />
                </div>
            </div>
        );
    }
}

TemplateWrapper.propTypes = {
    children: PropTypes.any
};

export default TemplateWrapper;
